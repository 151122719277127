import React from 'react';
import {Box, Grid, GridItem, Container, Heading, Text} from '@chakra-ui/react';
import {PageHeader} from './PageHeader';
import {PageFooter} from "./PageFooter";
import {CheckCircleIcon} from '@chakra-ui/icons'

export const PageLayout = ({children}) => {
    return (
        <>
            <Box pt={0}>
                <PageHeader/>
                <Container className="main-contianer" maxW="container.lg">
                    <Grid mb={20} templateColumns="repeat(2, 1fr)">
                        <GridItem rowSpan={2} colSpan={1} pt={[0, 0, 20]}>
                            <Heading as='h5' size='md' mb={5}>
                                Réalisez votre évaluation pour :
                            </Heading>

                            <Box className="text-icon-conatiner">
                                <Box className="icon-text" mb="6">
                                    <Box className="icon">
                                        <CheckCircleIcon w={8} h={8}/>
                                    </Box>
                                    <Box>
                                        <Text>
                                            Découvrir combien de kilos vous pourriez perdre avec l'aide de <b className={'orange'}>Shape®</b> tout en adoptant les bonnes résolutions.
                                        </Text>
                                    </Box>
                                </Box>
                                <Box className="icon-text" mb="6">
                                    <Box className="icon">
                                        <CheckCircleIcon w={8} h={8}/>
                                    </Box>
                                    <Box>
                                        <Text>
                                            Calculer en combien de temps vous pourriez alors atteindre votre objectif de poids en étant accompagné par <b className={'orange'}>Shape®</b>.
                                        </Text>
                                    </Box>
                                </Box>
                                <Box className="icon-text" mb="6">
                                    <Box className="icon">
                                        <CheckCircleIcon w={8} h={8}/>
                                    </Box>
                                    <Box>
                                        <Text>
                                            Confirmer que le produit <b className={'orange'}>Shape®</b> est potentiellement efficace et qu'il est bien adapté à votre situation actuelle.
                                        </Text>
                                    </Box>
                                </Box>
                            </Box>
                        </GridItem>
                        <GridItem className="form-wrapper" rowSpan={2}>
                            <Box className="form-container" mt={{base: 0, md: -20}} boxShadow="xl" mx="0"
                                 ml={{base: 0, md: 10}} p="8" rounded="md">
                                {children}
                            </Box>
                        </GridItem>
                    </Grid>
                </Container>

                <PageFooter/>
            </Box>
        </>
    );
};
