import './App.scss';
import {ChakraProvider, extendTheme} from '@chakra-ui/react'
import {Stepper} from './comnponents/Stepper';
import ReactGA from 'react-ga';
import {IntlProvider} from "react-intl";
import French from './lang/fr.json';
import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/de';
import 'moment/locale/nl';
import 'moment/locale/en-gb';
import '@fontsource/inter/400.css';
import '@fontsource/inter/700.css';

const messages = French;
const locale = 'fr';
moment.locale('fr');

const theme = extendTheme({
    fonts: {
        heading: "'Inter', 'sans-serif'",
        body: "'Inter', 'sans-serif'",
    }
})

document.title = 'Shape® - ' + messages.title;

function App() {
    ReactGA.initialize('UA-183263461-14');
    ReactGA.pageview(window.location.pathname + window.location.search);

    return (
        <div className="App">
            <IntlProvider locale={locale} messages={messages} defaultLocale="de">
                <ChakraProvider theme={theme}>
                    <Stepper/>
                </ChakraProvider>
            </IntlProvider>
        </div>
    );
}

export default App;
